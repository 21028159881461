<template>
  <div class="container">
    <line-chart v-if="loaded" :chartdata="chartdata" :options="options" />
  </div>
</template>

<script>
import LineChart from "./LineChart.vue";

export default {
  name: "LineChartContainer",
  components: { LineChart },
  data: () => ({
    loaded: false,
    chartdata: {
      labels: [],
      datasets: [
        {
          label: "DAU",
          backgroundColor: "#ff9f0a7f",
          borderColor: "#ff9f0a",
          borderWidth: 5,
          data: []
        }
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: true
    }
  }),
  async mounted() {
    this.loaded = false;
    try {
      var _this = this;
      this.axios.get("analytics/dau").then(res => {
        res.data.forEach(element => {
          let date = new Date(new Date(element.date).toDateString());
          while (
            _this.getEmptyDate() !== null &&
            +_this.getEmptyDate() !== +date
          ) {
            _this.chartdata.labels.push(_this.getEmptyDate().toDateString());
            _this.chartdata.datasets[0].data.push(0);
          }
          _this.chartdata.labels.push(date.toDateString());
          _this.chartdata.datasets[0].data.push(element.value);
        });
        _this.loaded = true;
      });
    } catch (e) {
      //   console.error(e);
    }
  },
  methods: {
    getEmptyDate() {
      let tomorrow =
        this.chartdata.labels.length > 0
          ? new Date(this.chartdata.labels[this.chartdata.labels.length - 1])
          : null;
      if (tomorrow === null) return null;
      tomorrow.setDate(tomorrow.getDate() + 1);
      return new Date(new Date(tomorrow.toUTCString()).toDateString());
    }
  }
};
</script>
