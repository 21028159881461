import md5 from "js-md5";

export default {
  methods: {
    hash(str) {
      if (str === undefined || str === null) return undefined;
      return md5(str);
    }
  }
};
