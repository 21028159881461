<template>
  <Admin>
    <div role="main-admin" class="col-md-9 ml-sm-auto col-lg-10 px-4">
      <div
        class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
      >
        <h1 class="h2">iTileMaps DAU</h1>
      </div>

      <div class="container">
        <div class="row">
          <LineChartContainer></LineChartContainer>
        </div>
      </div>
    </div>
  </Admin>
</template>

<script>
import Admin from "@/components/Admin.vue";
import LineChartContainer from "@/components/LineChartContainer.vue";

export default {
  name: "MapsDau",
  components: {
    Admin,
    LineChartContainer
  }
};
</script>
