<template>
  <div id="app">
    <nav class="navbar navbar-white navbar-bg fixed-top flex-md-nowrap p-0">
      <b-link class="navbar-brand col-sm-3 col-md-2 mr-0" :to="{ name: 'home' }"
        >KleMiX.com</b-link
      >
      <ul class="navbar-nav px-3">
        <li class="nav-item text-nowrap">
          <a class="nav-link" href="#" @click="signOut">Sign out</a>
        </li>
      </ul>
    </nav>

    <div class="container-fluid">
      <div class="row">
        <nav class="col-md-2 d-none d-md-block sidebar">
          <div class="sidebar-sticky">
            <ul class="nav flex-column">
              <li class="nav-item">
                <b-link class="nav-link" :to="{ name: 'admin' }" exact
                  ><span data-feather="home"></span>Dashboard</b-link
                >
              </li>
              <li class="nav-item">
                <b-link class="nav-link" :to="{ name: 'messages' }"
                  ><span data-feather="file"></span>Messages
                  <RedPill :count="unreadMessages"></RedPill
                ></b-link>
              </li>
              <li class="nav-item">
                <b-link class="nav-link" :to="{ name: 'profile' }">
                  <span data-feather="users"></span>
                  Profile
                </b-link>
              </li>
            </ul>
            <h6
              class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted"
            >
              <span>Analytics</span>
            </h6>
            <ul class="nav flex-column">
              <li class="nav-item">
                <b-link class="nav-link" :to="{ name: 'itm_dau' }" exact
                  >iTileMaps DAU</b-link
                >
              </li>
            </ul>
          </div>
        </nav>

        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import RedPill from "@/components/RedPill.vue";

export default {
  name: "Admin",
  components: {
    RedPill
  },
  data() {
    return {
      unreadMessages: 0
    };
  },
  mounted() {
    var _this = this;
    this.axios.get("messages/unread_count").then(function(response) {
      _this.unreadMessages = response.data;
      _this.$emit("unreadMessages", _this.unreadMessages);
    });
  },
  methods: {
    signOut() {
      this.$auth.logout();
    }
  }
};
</script>

<style lang="sass">
@import '@/sass/dashboard.sass'
</style>
