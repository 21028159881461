<template>
  <Admin>
    <div role="main-admin" class="col-md-9 ml-sm-auto col-lg-10 px-4">
      <div
        class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
      >
        <h1 class="h2">Messages</h1>
      </div>

      <div class="card-deck">
        <div
          v-for="message in messages"
          class="card mb-4"
          style="max-width: 18rem; min-width: 18rem;"
          :key="message.id"
        >
          <div class="card-body text-center">
            <p>
              <img
                class="rounded-circle img-fluid"
                :src="
                  'https://www.gravatar.com/avatar/' +
                    hash(message.email) +
                    '?d=mp'
                "
                alt="card image"
              />
            </p>
            <p class="card-text">
              <small class="text-muted"
                >From:
                <a :href="'mailto:' + message.email">{{
                  message.email
                }}</a></small
              >
            </p>

            <h5 class="card-title">{{ message.subject }}</h5>
            <p class="card-text">{{ message.message }}</p>
          </div>

          <div class="card-footer">
            <p class="card-text">
              <small class="text-muted">Received {{ message.created }}</small
              ><br />
              <small class="text-muted" v-if="message.read != null"
                >Read {{ message.read }}</small
              >
            </p>
            <button
              @click="markRead(message.id)"
              class="btn btn-primary"
              v-if="message.read == null"
            >
              Mark as read
            </button>
            &nbsp;
            <button
              @click="deleteMessage(message.id)"
              class="btn btn-danger"
              v-if="message.deleted == null"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </Admin>
</template>

<script>
import Admin from "@/components/Admin.vue";
import hash from "@/mixins/hash.js";

export default {
  name: "Dashboard",
  components: {
    Admin
  },
  mixins: [hash],
  data() {
    return {
      messages: []
    };
  },
  mounted() {
    var _this = this;
    this.axios.get("messages").then(function(response) {
      _this.messages = response.data;

      _this.messages.forEach(m => {
        // we have string dates, so parse them and convert to UTC
        m.created = new Date(m.created).toUTCString();
        if (m.read != null) m.read = new Date(m.read).toUTCString();
      });
    });
  },
  methods: {
    markRead(id) {
      var _this = this;
      this.axios.patch("messages/" + id + "/read").then(function() {
        _this.messages.find(m => m.id == id).read = new Date().toUTCString();
      });
    },
    deleteMessage(id) {
      var _this = this;
      this.axios.delete("messages/" + id).then(function() {
        _this.messages = _this.messages.filter(m => m.id != id);
      });
    }
  }
};
</script>
