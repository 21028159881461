<template>
  <Admin>
    <div role="main-admin" class="col-md-9 ml-sm-auto col-lg-10 px-4">
      <div
        class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
      >
        <h1 class="h2">Hello, {{ $auth.user().username }}</h1>
      </div>
      <div class="card">
        <div class="card-body">
          <p>
            <img
              class="rounded-circle img-fluid"
              :src="
                'https://www.gravatar.com/avatar/' +
                  hash(this.$auth.user().email) +
                  '?d=mp'
              "
              alt="card image"
            />
          </p>

          <h5 class="card-title">Profile</h5>
          <p></p>
          <b-form @submit="updateUser">
            <b-form-group
              id="input-group-7"
              label="Enter email"
              label-for="input-7"
            >
              <b-form-input
                id="input-7"
                v-model="userUpdate.email"
                type="text"
                autocomplete="one-time-code"
                required
                placeholder="Enter your e-mail"
              ></b-form-input>
            </b-form-group>

            <b-button type="submit" variant="primary">Update E-mail</b-button>
          </b-form>
          <br />
          <p></p>
          <h5 class="card-title">TFA</h5>
          <b-button
            v-if="$auth.user().otpSecret == null"
            variant="primary"
            @click="enableTFA"
            >Enable</b-button
          >

          <b-button
            v-if="$auth.user().otpSecret != null"
            variant="danger"
            @click="showDisableTFA"
            >Disable</b-button
          >
        </div>
      </div>
      <br />

      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Change password</h5>
          <b-alert
            :show="passwordChanged"
            variant="success"
            dismissible
            @dismissed="hidePasswordChange"
            >Password has been changed!</b-alert
          >
          <b-alert
            :show="passwordFailed"
            variant="danger"
            dismissible
            @dismissed="hidePasswordChange"
            >Something went wrong!</b-alert
          >
          <b-form @submit="changePassword" v-if="showPasswordChange">
            <b-form-group
              id="input-group-1"
              label="Current Password"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="passwordChange.oldPassword"
                type="password"
                autocomplete="current-password"
                required
                placeholder="Enter current password"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="New Password"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                v-model="passwordChange.newPassword"
                type="password"
                autocomplete="new-password"
                required
                placeholder="Enter new password"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-3"
              label="Confirm Password"
              label-for="input-3"
            >
              <b-form-input
                id="input-3"
                v-model="passwordChange.confirmedPassword"
                type="password"
                autocomplete="new-password"
                required
                placeholder="Enter new password again"
              ></b-form-input>
            </b-form-group>

            <b-button type="submit" variant="primary">Submit</b-button>
          </b-form>
        </div>
      </div>
      <br />

      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Recent Logins</h5>

          <b-table
            striped
            hover
            :items="logins"
            :fields="loginFields"
            :busy="loadingLogins"
          >
            <template v-slot:cell(index)="data">
              {{ data.index + 1 }}
            </template>
            <!-- A virtual composite column -->
            <template v-slot:cell(info)="data">
              <b-button variant="outline-warning" @click="showLoginInfo(data)"
                >Info</b-button
              >
            </template>
          </b-table>
        </div>
      </div>

      <b-modal
        v-model="showingLoginInfo"
        size="lg"
        hide-footer
        scrollable
        centered
        :title="loginInfoTitle"
      >
        <div id="mapkit-map" style="height: 256px; overflow-y: auto;"></div>
        <span v-html="loginInfo"></span>
      </b-modal>

      <b-modal
        v-model="showingTfaEnable"
        centered
        title="Enable TFA"
        @ok="createTFA"
      >
        <h5 class="card-title">Scan this code:</h5>
        <div class="text-center align-items-center">
          <img :src="qrdata" :width="qrsize" :height="qrsize" />
        </div>

        <b-form @submit="createTFA">
          <b-form-group
            id="input-group-4"
            label="Enter verification code"
            label-for="input-4"
          >
            <b-form-input
              id="input-4"
              v-model="tfaCreate.otpCode"
              type="text"
              autocomplete="one-time-code"
              required
              placeholder="Enter TFA code"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-5"
            label="Confirm with your Password"
            label-for="input-5"
          >
            <b-form-input
              id="input-5"
              v-model="tfaCreate.password"
              type="password"
              autocomplete="current-password"
              required
              placeholder="Enter password"
            ></b-form-input>
          </b-form-group>
        </b-form>
      </b-modal>

      <b-modal v-model="showingTfaDisable" centered title="Disable TFA">
        <b-form @submit="disableTFA">
          <b-form-group
            id="input-group-6"
            label="Confirm with your Password"
            label-for="input-6"
          >
            <b-form-input
              id="input-6"
              v-model="tfaDisable.password"
              type="password"
              autocomplete="current-password"
              required
              placeholder="Enter password"
            ></b-form-input>
          </b-form-group>
        </b-form>

        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              variant="danger"
              size="sm"
              class="float-right"
              @click="disableTFA"
            >
              Disable
            </b-button>
            <b-button
              variant="light"
              size="sm"
              class="float-right"
              @click="showingTfaDisable = false"
            >
              Cancel
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>
  </Admin>
</template>

<script>
import Admin from "@/components/Admin.vue";
import hash from "@/mixins/hash.js";
import qrcode from "qrcode-generator";

export default {
  name: "Dashboard",
  components: {
    Admin
  },
  mixins: [hash],
  data() {
    return {
      loginFields: [
        { key: "index", label: "#" },
        {
          key: "date",
          label: "Date",
          formatter: value => {
            return new Date(value).toUTCString();
          }
        },
        {
          key: "location",
          label: "From",
          formatter: (value, key, item) => {
            return this.shortLocationData(item.location, item.ip);
          }
        },
        {
          key: "info",
          label: ""
        }
      ],
      logins: [],
      loadingLogins: true,
      showingLoginInfo: false,
      passwordChange: {
        oldPassword: "",
        newPassword: "",
        confirmedPassword: ""
      },
      showPasswordChange: true,
      passwordChanged: false,
      passwordFailed: false,
      passwordChanging: false,
      showingTfaEnable: false,
      showingTfaDisable: false,
      tfaEnable: {
        otpSecret: ""
      },
      tfaDisable: {
        username: "",
        password: ""
      },
      tfaCreate: {
        otpSecret: "",
        otpCode: "",
        password: ""
      },
      qrdata: "",
      qrsize: 100,
      loginMap: null,
      mapkitToken: {},
      loginInfo: "",
      loginInfoTitle: "",
      userUpdate: {
        id: -1,
        username: "",
        password: "",
        email: ""
      }
    };
  },
  mounted() {
    this.userUpdate.email = this.$auth.user().email;

    var _this = this;
    this.axios.get("users/me/logins").then(response => {
      _this.logins = response.data;
      _this.loadingLogins = false;
    });

    if (document.getElementById("map-kit-script")) {
      // was already loaded
      this.initMapKit();
    } else {
      var scriptTag = document.createElement("script");
      scriptTag.src = "https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js";
      scriptTag.id = "map-kit-script";
      scriptTag.onload = () => this.initMapKit();
      document.getElementsByTagName("head")[0].appendChild(scriptTag);
    }
  },
  methods: {
    shortLocationData(location, ip) {
      let loc = JSON.parse(location);
      return loc.city + ", " + loc.country + " (" + loc.isp + ", " + ip + ")";
    },
    showLoginInfo(data) {
      this.showingLoginInfo = true;
      let loc = JSON.parse(data.item.location);
      let ip = data.item.ip;

      this.loginInfo =
        "as: " +
        loc.as +
        "<br>" +
        "city: " +
        loc.city +
        "<br>" +
        "country: " +
        loc.country +
        "<br>" +
        "country code:" +
        loc.countryCode +
        "<br>" +
        "isp: " +
        loc.isp +
        "<br>" +
        "lat: " +
        loc.lat +
        "<br>" +
        "lon: " +
        loc.lon +
        "<br>" +
        "org: " +
        loc.org +
        "<br>" +
        "query: " +
        loc.query +
        "<br>" +
        "region: " +
        loc.region +
        "<br>" +
        "region name: " +
        loc.regionName +
        "<br>" +
        "status: " +
        loc.status +
        "<br>" +
        "timezone: " +
        loc.timezone +
        "<br>" +
        "zip: " +
        loc.zip +
        "<br>";

      this.loginInfoTitle =
        loc.city + ", " + loc.country + " (" + loc.isp + ", " + ip + ")";
      var _this = this;
      this.$nextTick(() => {
        this.showMap(loc.lat, loc.lon, _this.loginInfoTitle);
      });
    },
    updateUser(evt) {
      evt.preventDefault();

      // these can't be changed here atm
      this.userUpdate.id = this.$auth.user().id;
      this.userUpdate.username = this.$auth.user().username;
      this.userUpdate.password = "";

      var _this = this;
      this.axios.put("users/", this.userUpdate).then(res => {
        _this.$auth.user().email = res.data.email; // update gravatar
      });
    },
    changePassword(evt) {
      evt.preventDefault();

      if (this.passwordChanging) return;

      this.passwordChanging = true;

      var _this = this;
      this.axios
        .patch("users/me/change_password", this.passwordChange)
        .then(response => {
          _this.showPasswordChange = false;
          _this.passwordChanging = false;

          if (response.status != 200) {
            throw new Error(response.status);
          }
          _this.passwordChanged = true;
        })
        .catch(() => {
          _this.showPasswordChange = false;
          _this.passwordFailed = true;
          _this.passwordChanging = false;
        });
    },
    enableTFA() {
      var _this = this;
      this.axios.patch("users/me/enable_tfa").then(response => {
        _this.tfaEnable = response.data;
        _this.showingTfaEnable = true;

        _this.createQR();
      });
    },
    createTFA(bvModalEvt) {
      bvModalEvt.preventDefault();

      // We have a full url here, so we need to extract only secret
      var query = this.tfaEnable.otpSecret.indexOf("?");
      var search = this.tfaEnable.otpSecret.substring(query + 1);
      var otpData = JSON.parse(
        '{"' +
          decodeURI(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
      this.tfaCreate.otpSecret = otpData.secret;

      var _this = this;
      this.axios.patch("users/me/create_tfa", this.tfaCreate).then(() => {
        _this.$auth.user().otpSecret = _this.tfaEnable.otpSecret;
        _this.$auth.user().otpCounter = 0;
        _this.showingTfaEnable = false;
        _this.tfaCreate.otpSecret = "";
        _this.tfaCreate.otpCode = "";
        _this.tfaCreate.password = "";
      });
    },
    showDisableTFA() {
      this.showingTfaDisable = true;
    },
    disableTFA() {
      this.tfaDisable.username = this.$auth.user().username;

      var _this = this;
      this.axios.patch("users/me/disable_tfa", this.tfaDisable).then(() => {
        _this.$auth.user().otpSecret = null;
        _this.$auth.user().otpCounter = null;
        _this.showingTfaDisable = false;
        _this.tfaDisable.username = "";
      });
    },
    hidePasswordChange() {
      if (this.passwordChanged) {
        this.passwordChange.oldPassword = "";
        this.passwordChange.newPassword = "";
        this.passwordChange.confirmedPassword = "";
      }
      this.passwordChanged = false;
      this.passwordFailed = false;
      this.showPasswordChange = true;
    },
    createQR() {
      var typeNumber = 6;
      var errorCorrectionLevel = "L";
      var qr = qrcode(typeNumber, errorCorrectionLevel);
      qr.addData(this.tfaEnable.otpSecret);
      qr.make();

      let cellSize = 7;
      let margin = cellSize * 2;
      this.qrsize = qr.getModuleCount() * cellSize + margin * 2;
      this.qrdata = qr.createDataURL(cellSize, margin);
    },
    initMapKit() {
      var _this = this;
      window.mapkit.init({
        authorizationCallback: done => {
          _this.axios
            .get("mapkit/token")
            .then(res => {
              _this.mapkitToken = res.data;
              done(_this.mapkitToken.token);
            })
            .catch(error => {
              // eslint-disable-next-line no-console
              console.error(error);
            });
        }
      });
    },
    showMap(lat, lon, name) {
      if (this.loginMap) this.loginMap.destroy();

      const center = new window.mapkit.Coordinate(lat, lon),
        span = new window.mapkit.CoordinateSpan(0.5, 0.5),
        region = new window.mapkit.CoordinateRegion(center, span);

      let map = new window.mapkit.Map("mapkit-map", {
        region: region,
        showsCompass: window.mapkit.FeatureVisibility.Hidden,
        showsZoomControl: false,
        showsMapTypeControl: false,
        colorScheme: window.mapkit.Map.ColorSchemes.Dark
      });

      const annotation = new window.mapkit.MarkerAnnotation(center, {
        title: name,
        subtitle: "Login location",
        displayPriority: 1000
      });

      map.addAnnotation(annotation);

      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: light)").matches
      ) {
        map.colorScheme = window.mapkit.Map.ColorSchemes.Light;
      }

      this.loginMap = map;
    }
  }
};
</script>
