<template>
  <Admin @unreadMessages="unreadMessagesReceived">
    <div role="main-admin" class="col-md-9 ml-sm-auto col-lg-10 px-4">
      <div
        class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
      >
        <h1 class="h2">Dashboard</h1>
        <!-- <div class="btn-toolbar mb-2 mb-md-0">
          <div class="btn-group mr-2">
            <button type="button" class="btn btn-sm btn-outline-secondary">
              Share
            </button>
            <button type="button" class="btn btn-sm btn-outline-secondary">
              Export
            </button>
          </div>
          <button
            type="button"
            class="btn btn-sm btn-outline-secondary dropdown-toggle"
          >
            <span data-feather="calendar"></span>
            This week
          </button>
        </div> -->
      </div>

      <div class="card-group">
        <div class="card mb-4" style="max-width: 18rem; min-width: 18rem;">
          <div class="card-body">
            <h5 class="card-title">
              Messages
              <RedPill :count="unreadMessages"></RedPill>
            </h5>
            <p class="card-text">Read messages from site visitors.</p>
            <b-link :to="{ name: 'messages' }" class="card-link">Read</b-link>
          </div>
        </div>
        <div class="card mb-4" style="max-width: 18rem; min-width: 18rem;">
          <div class="card-body">
            <h5 class="card-title">Profile</h5>
            <p class="card-text">
              Change password or email, manage your authentication tokens.
            </p>
            <b-link :to="{ name: 'profile' }" class="card-link">Manage</b-link>
          </div>
        </div>
      </div>
    </div>
  </Admin>
</template>

<script>
import Admin from "@/components/Admin.vue";
import RedPill from "@/components/RedPill.vue";

export default {
  name: "Dashboard",
  components: {
    Admin,
    RedPill
  },
  data() {
    return {
      unreadMessages: 0
    };
  },
  methods: {
    unreadMessagesReceived(count) {
      this.unreadMessages = count;
    }
  }
};
</script>
