<template>
  <span v-if="count > 0" class="badge badge-pill badge-danger">{{
    count
  }}</span>
</template>

<script>
export default {
  name: "RedPill",
  props: ["count"]
};
</script>
