<script>
import { Line } from "vue-chartjs"; // Pie, or Doughnut can also be used
export default {
  extends: Line,
  props: {
    chartdata: { type: Object, default: null },
    options: { type: Object, default: null }
  },
  mounted() {
    this.renderChart(this.chartdata, this.options);
  }
};
</script>
