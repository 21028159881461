<template>
  <Admin>
    <div role="main-admin" class="col-md-9 ml-sm-auto col-lg-10 px-4">
      <div
        class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
      >
        <h1 class="h2">404</h1>
      </div>

      <h2 class="h3">The content you were looking for was not found!</h2>
    </div>
  </Admin>
</template>

<script>
import Admin from "@/components/Admin.vue";

export default {
  name: "Dashboard",
  components: {
    Admin
  }
};
</script>
